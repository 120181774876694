import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ProjectNav from "../components/projectNav";
import CakeContent from "../components/cake-content";
import BackLink from "../components/back-up";
import IndexNav from "../components/project-nav";

export default class Cake extends Component { 
  constructor() {
    super();
    this.state = {
      brandedBG: true,
      projNav: false // Add this
    };
  }
  
  componentDidUpdate() {
    window.onscroll = ('scroll', () => {
      if(window.pageYOffset < 20) {
        this.setState({ brandedBG: true });
      }
      else {
        this.setState({ brandedBG: false });
      }
    });
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  renderBG = () => { {/* Add this */}
    if(this.state.brandedBG){
      if(this.state.projNav){
        return 'cake-bg branded-bg proj-nav-open'
      } else {
        return 'cake-bg branded-bg proj-nav-close'
      }
    } else{
      return 'default-bg'
    }
  }
  navClick = () => { {/* Add this */}
    this.setState({ projNav: !this.state.projNav });
  }

  render() {
    return (
      <div className={'project-content'} id={'cake-home'}>
        <Helmet>
          <body className={this.renderBG()}></body>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"></meta>
          <title>Wes Wong Design: CAKE Branding</title>
          <meta name="description" content="Design portfolio of Wes Wong"></meta>
        </Helmet>
        <ProjectNav onClick={this.navClick} />{/* Add this */}
        <IndexNav closeWindow={this.navClick} />
        <CakeContent />
        <BackLink />
      </div>
    )
  }
}