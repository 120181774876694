import React from 'react';
import { Fade } from 'react-reveal';
import Image from '../components/Image';
import CakeBrand from '../images/cake-branding.svg';

const CakeIDContent = () => { 
  return (
  <div id={'main-content'}>        
    <section className={'project-detail-hero'} >
      <h1 className={'project-name two-piece-combo'} >
        <span className={'slide-top a1'}>CAKE </span> 
        <span className={'slide-top a2'}>Identity </span>  
        <span className={'slide-top a3'}>&amp; Branding</span>
      </h1>
    <Fade >
      <div className={'contribution two-piece-combo'} >
        <div className={'icon'} >&#xe908;</div>
        <ul>
          <li>Naming Direction</li>
          <li>Creative Direction</li>
          <li>Visual Identity Design</li>
        </ul>        
      </div>
    </Fade>
    </section>

    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          A Leap of Faith
        </h2>
        <Fade fraction={.71}>
        <Image
          alt="Leapset, the brand before CAKE" 
          filename="leapset-old-color.png"
          className='leapset-logo'
        />
        <p className={'caption centered-text'}>(Old logo, not my design)</p>
        </Fade>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          4 Years into operation, the tech incubator “Leapset” found product market fit in building POS systems for the restaurant industry. The startup quickly acquired 2 other products, began operating in 3 cities, and had ambitions to grow the product suite. We knew it was necessary to bring everything under one&nbsp;umbrella.
        </p><p>
          As the in-house Director of Design with branding experience, I was responsible for leading the effort. Alongside the Director of Marketing and an interdepartmental team, we diverged and refined what was to become the company’s new identity.
        </p>

      </div>
    </section>
   
    </Fade>

    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          ...And Eat it too
        </h2>

        <Image
            alt="Notes from 100 Monkeys naming options" 
            filename="cake-name-selection.png"
            className='cake-name-selection'
        />
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          The team dove deep into a process of redefining the company’s core mission. It needed to be broad enough to cover our current and future products, yet specific enough to give the company a true north.
        </p><p>
          In the end, a common theme emerged:
        </p>
        <Fade>
        <blockquote className={'pull-quote'}>
          Running a restaurant is hard. We bring smart technology solutions to make it easier for the people who operate them.
        </blockquote>  
        </Fade>
        <p>
          I led the process of vetting &amp; selecting our consultants, then managed the creative direction for our new name and logo. Along with the mission and company goals, I added the stretch goal of finding a witty double entendre.
        </p>
        <p>
          I worked closely with 100 monkeys, the world&rsquo;s first naming firm, to narrow down options for the name. Our internal branding team made the final decision, we &ldquo;had our CAKE.&rdquo;
        </p>
      </div>
    </section>
    </Fade>
 
    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          Icing on the...
        </h2>
        <Image
            alt="CAKE logo by OVO" 
            filename="cake-logo-1.png"
            className='cake-bw-logo'
        />
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          Building off the momentum from the naming exercise, I partnered with talented designers at OVO to establish the logo. Our shared love for typography led to a beautiful logotype.
        </p>
        <p>
          We collaborated closely on defining the rest of our visual identity including colors and base typographic rules.
        </p>
        <p>
          This was the result:
        </p>
      </div>
    </section>
    </Fade>

    <Fade>
    <section className={'project-info cake-style'} >
      <img id={'cake-branding'} src={CakeBrand} />
    </section>
    </Fade>


  </div>

  )
}

export default CakeIDContent;